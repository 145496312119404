/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
  
        /**
         * jQuery function to prevent default anchor event and take the href * and the title to make a share pupup
         * http://codepen.io/patrickkahl/pen/DxmfG
         *
         * @param  {[object]} e           [Mouse event]
         * @param  {[integer]} intWidth   [Popup width defalut 500]
         * @param  {[integer]} intHeight  [Popup height defalut 400]
         * @param  {[boolean]} blnResize  [Is popup resizeabel default true]
         */
        $.fn.customerPopup = function (e, intWidth, intHeight, blnResize) {
          // Prevent default anchor event
          e.preventDefault();
          // Set values for window
          intWidth = intWidth || '500';
          intHeight = intHeight || '400';
          strResize = (blnResize ? 'yes' : 'no');
          // Set title and open popup with focus on it
          var strTitle = ((typeof this.attr('title') !== 'undefined') ? this.attr('title') : 'Social Share'),
              strParam = 'width=' + intWidth + ',height=' + intHeight + ',resizable=' + strResize,            
              objWindow = window.open(this.attr('href'), strTitle, strParam).focus();
        };
        $('.customer.share').on("click", function(e) {
          $(this).customerPopup(e);
        });

        $('.comments a').contents().unwrap();

        // Set .scrollable height
        function setHeight() {
          var width = $(window).width();
          if (width > 768) {
            var height = $(window).height() - $('#avisos-elhc').outerHeight() - $('#barra-elhc').outerHeight();
            $('.scrollable').css('height',height);
          } else {
            $('.scrollable').css('height','auto');
          }
        }
        setHeight();
        $(window).resize(function() {
          setHeight();
        });

          if(!window.location.hash) {
            $('.today').parent().scrollTo('.today', 1000);
            $('body').parent().scrollTo('.today', 1000);
          }

        // Scroll to today on load
        // if (width > 768) {
        //   if(!window.location.hash) {
        //     $('.today').parent().scrollTo('.today', 1000);
        //   }
        // } else {
        //   if(!window.location.hash) {
        //     $('body').scrollTo('.today', 1000);
        //   }
        // }

        // Instagram
        function createPhotoElement(photo) {
          var innerHtml = $('<img>')
            .addClass('instagram-image')
            .attr('src', photo.images.standard_resolution.url);

          innerHtml = $('<a>')
            .attr('target', '_blank')
            .attr('href', photo.link)
            .append(innerHtml);

          return $('<div>')
            .addClass('instagram-placeholder')
            .attr('id', photo.id)
            .append(innerHtml);
        }

        function didLoadInstagram(event, response) {
          console.log(response);
          var that = this;

          $.each(response.data, function(i, photo) {
            $(that).append(createPhotoElement(photo));
          });
        }

        // $('#instagram').on('didLoadInstagram', didLoadInstagram);
        // $('#instagram').instagram({
        //   hash: 'hipster',
        //   clientId: '7f891a6ba8134dafa3792caf7d5601a4',
        //   // accessToken: '1386770630.7f891a6.26d13184489b44e3990e513a17d3968c',
        //   count: 50
        // });

        // var feed = new Instafeed({
        //   get: 'tagged',
        //   tagName: 'awesome',
        //   clientId: '7f891a6ba8134dafa3792caf7d5601a4',
        //   accessToken: '1386770630.7f891a6.26d13184489b44e3990e513a17d3968c'
        // });
        // feed.run();

        $('.naver-handle').click(function( event ) {
          event.preventDefault();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // Toggle comments
        $('.comment-reply-title').each(function() {
          $(this).click(function() {
            $(this).siblings('.comment-form').slideToggle(400);
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
